import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CrashReport, Application, CrashGroup, CallStack, MissingSymbolsFile, Workspace } from 'app/_models';
import { CrashesService, AlertService, ApplicationService, MissingSymbolsService, AuthenticationService } from 'app/_services';
import { ByteCountFormatter, PlatformFormatter, DateFormatter, ApplicationFormatter } from 'app/_helpers';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApplicationLogsComponent } from 'app/_components';
import * as moment from 'moment'
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UploadSymbolsComponent } from 'app/_global.modals';
import { EditCrashGroupModal } from '../edit.crash.group.modal/edit.crash.group.modal';

export enum CrashReportTabState {
    CallStack = 'callstack',
    Raw = 'raw',
    Attachment = 'attachment',
}

@Component({
    templateUrl: 'crash.report.component.html',
    styleUrls: [
        'crash.report.component.css'
    ]
})
export class CrashReportComponent implements OnInit, OnDestroy {

    CrashReportTabState = CrashReportTabState

    workspace: Workspace
    application: Application
    crashGroup: CrashGroup
    crashReport: CrashReport
    callStack: CallStack
    rawReport: string | null
    attachment: string | null
    missingSymbols: MissingSymbolsFile[]
    currentTabState = CrashReportTabState.CallStack

    @ViewChild('applicationLogs') applicationLogs: ApplicationLogsComponent
    @ViewChild('editCrashGroupModal') editCrashGroupModal: EditCrashGroupModal
    @ViewChild('uploadSymbolsModal') uploadSymbolsModal: UploadSymbolsComponent

    private currentApplicationSubscription: Subscription
    private currentCrashGroupSubscription: Subscription
    private routeParamsSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public byteCountFormatter: ByteCountFormatter,
        public platformFormatter: PlatformFormatter,
        private route: ActivatedRoute,
        private http: HttpClient,
        private crashesService: CrashesService,
        private missingSymbolsService: MissingSymbolsService,
        private applicationService: ApplicationService,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private applicationFormatter: ApplicationFormatter,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.loadCrashReport()

        this.routeParamsSubscription = this.route.params.subscribe(params => {
            this.loadCrashReport()
        })

        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
        })

        this.currentCrashGroupSubscription = this.crashesService.currentCrashGroup.subscribe((crashGroup) => {
            this.crashGroup = crashGroup
            if (this.crashGroup != null) {
                this.loadCrashReport()
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        this.currentCrashGroupSubscription?.unsubscribe()
        this.routeParamsSubscription?.unsubscribe()
    }

    onAnnotateButtonClick() {
        this.editCrashGroupModal.crashGroup = this.crashGroup
    }

    crashedAfterTimeText(): string {
        let diff = moment(this.crashReport.crashDate).diff(this.crashReport.appLaunchDate) / 1000
        return `${this.dateFormatter.duration(diff)}`
    }

    onCrashGroupUpdated() {
        this.reloadCrashGroup()
    }

    setCurrentTabState(tabState: CrashReportTabState) {
        this.currentTabState = tabState
        if (this.currentTabState == CrashReportTabState.Raw && this.rawReport == null) {
            this.http.get(this.crashReport.report.url, { responseType: 'text' }).toPromise().then((result => {
                this.rawReport = result

            })).catch(error => {
                this.alertService.handleError(error)
            })
        }

        if (this.currentTabState == CrashReportTabState.Attachment && this.attachment == null) {
            this.http.get(this.crashReport.attachment.url, { responseType: 'text' }).toPromise().then((result => {
                this.attachment = result

            })).catch(error => {
                this.alertService.handleError(error)
            })
        }
    }

    showUploadSymbolsModal() {
        this.uploadSymbolsModal.application = this.application
        this.uploadSymbolsModal.missingSymbols = this.missingSymbols
    }

    dismissMissingSymbolsAlert() {
        this.missingSymbols = null
    }

    copySourceCodeLocationToClipboard() {
        navigator.clipboard.writeText(this.crashGroup.sourceCodeLocation)
    }

    private reloadCrashGroup() {
        if (!this.crashGroup) { return }

        // Here we can fetch based on crash group id because it will only be called once the same group is updated
        this.crashesService.getCrashGroupById(this.crashGroup.id).then((response) => {
            this.crashesService.setCurrentCrashGroup(response.data)

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private loadCrashReport() {
        if (!this.workspace || !this.crashGroup) { return }

        let crashReportSlug = this.route.snapshot.params['crashReportSlug']
        if (crashReportSlug == null || (this.crashReport && this.crashReport.serialNumber == crashReportSlug)) {
            return
        }

        if (this.application) {
            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Crash Report #${crashReportSlug}`)
        } else {
            this.titleService.setTitle(`${this.workspace.name} | Crash Report #${crashReportSlug}`)
        }

        this.crashesService.getCrashGroupCrashReportWithSlug(this.crashGroup.id, crashReportSlug).then((crashReport) => {
            this.crashReport = crashReport.data
            this.reloadCrashReportDetails()
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadCrashReportDetails() {
        if (!this.crashReport) { return }

        this.currentTabState = CrashReportTabState.CallStack
        this.rawReport = null
        this.callStack = null
        this.missingSymbols = null
        this.attachment = null

        let applicationId = this.application ? this.application.id : null
        let getCallStackPromise = this.crashesService.getCrashReportCallStack(this.crashReport.id)
        let getMissingSymbolsPromise = this.missingSymbolsService.getMissingSymbolsFiles(applicationId, this.crashReport.id)

        Promise.all([getCallStackPromise, getMissingSymbolsPromise]).then((result) => {
            this.callStack = result[0]
            this.missingSymbols = result[1].data
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
