import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, WorkspaceMember, WorkspaceInvitation, AlertService, WorkspaceService, InvitationsService, InvitationState, UserService } from 'app/_services'
import { Workspace, Role } from 'app/_models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment'
import { RoleFormatter } from '../../_helpers/role.formatter';
import { DomSanitizer, SafeUrl, Title } from '@angular/platform-browser';
import { AppConfig } from 'app/app.config';
import { UserEditorModal } from 'app/user.editor/user.editor.modal';
import { Subscription } from 'rxjs';
import { DateFormatter } from 'app/_helpers';

@Component({
    templateUrl: 'people.settings.component.html',
    styleUrls: [
        'people.settings.component.css'
    ]
})
export class PeopleSettingsComponent implements OnInit, OnDestroy {

    workspace: Workspace
    canCreateUsers: boolean

    agent: WorkspaceMember
    admins: WorkspaceMember[] = []
    members: WorkspaceMember[] = []
    testers: WorkspaceMember[] = []
    membersCount: number

    invitations: WorkspaceInvitation[] = []

    newInvitationForm: FormGroup
    showInvitationForm: boolean
    canManageAdminUsers: boolean
    canManageMemberUsers: boolean
    invitationRoles: Role[]
    selectedRole: Role

    rolesDocumentationUrl: SafeUrl

    private userEditedSubscription: Subscription
    private userDeletedSubscription: Subscription

    @ViewChild('userEditor') userEditorModal: UserEditorModal

    constructor(
        public roleFormatter: RoleFormatter,
        public dateFormatter: DateFormatter,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private invitationsService: InvitationsService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspaceService,
        private titleService: Title
    ) {
        this.workspace = this.authenticationService.currentWorkspaceValue
        this.canCreateUsers = this.workspace.limits.canCreateUsers
    }

    ngOnInit() {
        this.titleService.setTitle("Settings | Team Members")

        let rolesDocumentationUrl = `${AppConfig.developerBaseUrl}/documentation/dashboard/user-roles/`
        this.rolesDocumentationUrl = this.sanitizer.bypassSecurityTrustUrl(rolesDocumentationUrl)

        this.newInvitationForm = this.formBuilder.group({
            emailInput: [null, Validators.required],
        })

        this.invitationRoles = this.roleFormatter.rolesManagedBy(this.workspace.role)

        // Compose a list of allowed roles base on the user's role in the workspace
        switch (this.workspace.role) {
            case Role.Agent:
                this.showInvitationForm = true
                this.canManageAdminUsers = true
                this.canManageMemberUsers = true
                this.selectedRole = Role.Member
                break
            case Role.Admin:
                this.showInvitationForm = true
                this.canManageAdminUsers = false
                this.canManageMemberUsers = true
                this.selectedRole = Role.Member
                break
            case Role.Member:
            case Role.Tester:
                this.showInvitationForm = false
                this.canManageAdminUsers = false
                this.canManageMemberUsers = false
                this.selectedRole = null
                break
        }

        this.userEditedSubscription = this.userService.userEdited.subscribe((result) => {
            this.reloadWorkspaceMembers()
        })

        this.userDeletedSubscription = this.userService.userDeleted.subscribe((result) => {
            this.reloadWorkspaceMembers()
        })

        this.reloadWorkspaceMembers()
        this.reloadWorkspaceInvitations()
    }

    ngOnDestroy() {
        this.userEditedSubscription?.unsubscribe()
    }

    sendInvitation() {
        this.invitationsService.sendInvitation(this.workspace.id, this.newInvitationForm.controls.emailInput.value, this.selectedRole, null).then((response) => {
            this.alertService.success("Invitation sent successfully.")

            this.newInvitationForm.reset()
            this.reloadWorkspaceInvitations()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    resendInvitation(invitation: WorkspaceInvitation) {
        this.invitationsService.updateInvitation(invitation.id, InvitationState.open).then((response) => {
            this.alertService.success("Invitation sent successfully.")
            this.reloadWorkspaceInvitations()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    cancelInvitation(invitation: WorkspaceInvitation) {
        this.invitationsService.updateInvitation(invitation.id, InvitationState.cacelled).then((response) => {
            this.reloadWorkspaceInvitations()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    onEditMemberClick(member: WorkspaceMember) {
        this.userEditorModal.workspaceMember = member
    }

    invitationExpiration(date: Date): string {
        return moment(date).format("MMM Do YYYY")
    }

    invitationExpired(invitation: WorkspaceInvitation): boolean {
        return invitation.state == InvitationState.expired
    }

    private reloadWorkspaceMembers() {
        this.workspaceService.getWorkspaceMembers(this.workspace).then((response) => {
            this.agent = response.data.find(member => { return member.role == Role.Agent })
            this.admins = response.data.filter(member => { return member.role == Role.Admin })
            this.members = response.data.filter(member => { return member.role == Role.Member })
            this.testers = response.data.filter(member => { return member.role == Role.Tester })
            this.membersCount = response.data.length

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadWorkspaceInvitations() {
        this.invitationsService.getWorkspaceInvitations(this.workspace.id, null).then((response) => {
            this.invitations = response.invitations

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
